/*login*/
:root {
  /* --color-corporativo:#8fb523;   */
  --color-corporativo: #0091ca;
  padding-top: env(safe-area-inset-top);
  padding-bottom: env(safe-area-inset-bottom);
  --color-button: #fdffff;
  --color-black: rgba(0, 0, 0, 0.4);

}

.nombre-tarifa {
  color: white;
}

.Logotipo {
  display: block;
  width: 37%;
  height: auto;
  max-width: 400px;
  margin: auto;
  margin-top: 55px;
  margin-bottom: 28px;
}

.modal-header-tarifas {
  color: white !important;
}

.modal-footer .btn-rojo-xl-tarifas {
  color: white !important;
}

.modal-footer .icon-black {
  color: white !important;
}

.LogotipoHome {
  display: block;
  width: 37%;
  height: auto;
  margin: auto;
  margin-top: 10%;
  margin-bottom: 15%;
}

.form-login {
  margin-top: 60px;
}


.btn-inicio {
  margin-top: 30px !important;
}

.form {
  margin-top: 40px !important;
}

.form-group {
  margin-top: 15px !important;
  margin-left: 20px;
  margin-right: 20px;
  border-bottom: 1px solid #c8c7cc;
  padding-top: 15px !important;
  padding-bottom: 15px !important;
  padding-right: 8px;
}

.cambiar-user {
  margin-bottom: 15px !important;
}

.label-RemenberPassword {
  margin-left: 15px;
  position: relative !important;
  bottom: 14px !important;
  left: 0px !important;
}


.icono-linea-sms:focus,
.icono-linea-sms:active,
.icono-linea-sms:visited,
.icono-linea-sms:hover,
.icono-linea-sms:focus-visible {
  border: 1px solid #005fa6 !important;
  border-radius: 20px;
  outline: none;
}

.icono-linea-llama:focus,
.icono-linea-llama:active,
.icono-linea-llama:visited,
.icono-linea-llama:hover,
.icono-linea-llama:focus-visible {
  border: 1px solid #8fb523 !important;
  border-radius: 20px;
  outline: none;
}

.icono-linea-gb:focus,
.icono-linea-gb:active,
.icono-linea-gb:visited,
.icono-linea-gb:hover,
.icono-linea-gb:focus-visible {
  border: 1px solid #fc6424 !important;
  border-radius: 20px;
  outline: none;
}

.num-contrato {
  color: var(--color-corporativo) !important;
}

ul.list-unstyled li {
  padding-bottom: 5px;
  padding-top: 5px;
  margin-bottom: 8px;
  margin-top: 5px;
}

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: var(--color-corporativo);
  border-color: var(--color-corporativo);
}

.modal-header-puk .icon-black-tarifas {
  color: black !important;
}